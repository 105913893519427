import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { AssetsImage, getAssetsAndAssetsImages, getLocalizedLabel, getUniqueResortIdsForOwner } from "./assetPublisher.util";
import { Card, CardBody, CardSubtitle, CardText, CardTitle, Collapse } from "reactstrap";
import { IAsset, WidgetOptions } from "./";
import { LocalizedCardContent, LocalizedContent, LocalizedSpecialCardContent, Site, WebContent, WithId } from "@maxxton/cms-api";
import ReactIdSwiper, { ReactIdSwiperProps } from "react-id-swiper";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { CMSAware } from "../../../containers/CmsProvider";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { Dispatch } from "redux";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import Masonry from "react-masonry-css";
import { MyEnvReducerAction } from "../../../redux/actions/myEnvAction";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { SmartLink } from "../../../components/SmartLink";
import { State } from "../../../redux";
import { connect } from "react-redux";
import { convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { forceUpdateSwiper } from "../image-gallery/imageGallery.utils";
import { getHideWidgetClass } from "../../../components/utils";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { isEqual } from "lodash";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../utils/loadableComponents.util";
import namespaceList from "../../../i18n/namespaceList";
import withChildrenProps from "../../../utils/withChildrenProps.utils";
import withEditModal from "../../../components/frontend-editing/WithEditModal";
import { withTabbedAssets } from "./withTabbedAssets";

const MasonryWrappedWithChildrenProps = withChildrenProps(Masonry);

const Swiper = loadable(() => loadableRetry(() => import("react-id-swiper")), {
    resolveComponent: (Swiper) => Swiper.default as React.ForwardRefExoticComponent<any>,
});
export interface AssetPublisherPropsBase extends PageWidgetBaseProps<WidgetOptions> {
    label: string;
    assets: IAsset[];
    context: CMSProvidedProperties;
    assetsImages: AssetsImage[];
    className: string;
    allSites: Array<Site & WithId>;
    ownerResortIds?: number[];
    tabbedAssets?: IAsset[];
}

interface AssetPublisherStoreProps {
    myEnvState: MyEnvState;
}
interface AssetPublisherDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction | MyEnvReducerAction>;
}
interface AssetPublisherProps extends CMSProvidedProperties, AssetPublisherPropsBase, AssetPublisherDispatchProps, AssetPublisherStoreProps {}
interface AssetPublisherState {
    sites: Array<Site & WithId>;
    disableWidget: boolean;
    assets: IAsset[];
    assetsImages: AssetsImage[];
    options: WidgetOptions;
    label: string;
}

enum LayoutType {
    CARD = "card",
    REVEALER = "revealer",
    ACCORDION = "useAsAccordion",
    NORMAL = "normal",
}
export type WebcontentWithId = WebContent & WithId;
const MODEL_TARGETS = ["webcontent"];

export class AssetPublisherBase extends React.PureComponent<AssetPublisherProps, AssetPublisherState> {
    constructor(props: AssetPublisherProps) {
        super(props);
        this.state = {
            sites: props.allSites,
            disableWidget: true,
            assets: props.assets || [],
            assetsImages: props.assetsImages || [],
            options: props.options,
            label: props.label,
        };
    }

    private swiper: typeof ReactIdSwiper = React.createRef();

    public componentDidMount() {
        const { options, isMyEnvWidget } = this.props;
        if (options.containerType === "slider" && options.slideLoop) {
            forceUpdateSwiper(this.swiper);
        }
    }

    public componentDidUpdate(prevProps: AssetPublisherProps) {
        const { options, context, myEnvState } = this.props;

        if (options.localized !== prevProps.options.localized) {
            const updatedLabel = getLocalizedLabel(options.localized, context);
            this.setState({ label: updatedLabel });
        }
        if (!isEqual(prevProps.options, options) || !isEqual(prevProps?.myEnvState?.ownerState?.ownerUnitsResortIds, myEnvState?.ownerState?.ownerUnitsResortIds)) {
            this.updateAssetsCallback(this.props);
            if (options.containerType === "slider" && options.slideLoop) {
                forceUpdateSwiper(this.swiper);
            }
        }
    }

    private updateAssetsCallback = (updatedProps: AssetPublisherProps) => {
        const { options, context } = updatedProps;
        this.setState({ options }, async () => {
            let uniqueResortIds: number[] = [];
            if (options?.enableLocationForOwner) {
                uniqueResortIds = await getUniqueResortIdsForOwner(context);
            }
            const { assets, assetsImages } = await getAssetsAndAssetsImages(options, context, uniqueResortIds);
            this.setState({ assets, assetsImages });
        });
    };

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const {
            context: { currentLocale, site },
            theme,
            className,
            tabbedAssets,
        } = this.props;
        const { assets, label, options } = this.state;
        const hideWidget: string | null = getHideWidgetClass(options, this.state.disableWidget);
        if (hideWidget === null) {
            return null;
        }
        const { sites } = this.state;
        const breakpointColumnsObj = {
            default: 4,
            1024: 3,
            768: 2,
            480: 1,
        };
        const sliderParams: ReactIdSwiperProps = {};
        sliderParams.spaceBetween = +options.spaceBetween || 10;
        if (options.containerType === "slider" && options.slideLoop) {
            sliderParams.loop = true;
            sliderParams.loopFillGroupWithBlank = true;
        }
        sliderParams.slidesPerView = +options.slidesPerView || 1;
        sliderParams.rebuildOnUpdate = true;
        sliderParams.navigation = {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        };
        if (options.slideBreakpoints) {
            sliderParams.breakpoints = {
                1024: {
                    slidesPerView: options.sneakPreview ? +options.slidesResponsiveDesktop + 0.5 : options.slidesResponsiveDesktop ? +options.slidesResponsiveDesktop : undefined,
                },
                768: {
                    slidesPerView: options.sneakPreview ? +options.slidesResponsiveTablet + 0.5 : options.slidesResponsiveTablet ? +options.slidesResponsiveTablet : undefined,
                },
                640: {
                    slidesPerView: options.sneakPreview ? +options.slidesResponsiveMobLandscape + 0.5 : options.slidesResponsiveMobLandscape ? +options.slidesResponsiveMobLandscape : undefined,
                },
                320: {
                    slidesPerView: options.sneakPreview ? +options.slidesResponsiveMobPortrait + 0.5 : options.slidesResponsiveMobPortrait ? +options.slidesResponsiveMobPortrait : undefined,
                },
            };
        }

        const showMoreIcon: JSX.Element = options?.plusIcon && !options.iconPropertiesForMore ? this.renderToggleIcon("plus") : this.renderToggleIcon(options.iconPropertiesForMore);
        const showLessIcon: JSX.Element = options?.plusIcon && !options.iconPropertiesForLess ? this.renderToggleIcon("minus") : this.renderToggleIcon(options.iconPropertiesForLess);

        // eslint-disable-next-line max-lines-per-function
        const assetData: JSX.Element[] = (tabbedAssets || assets)?.map((content: IAsset) => {
            const localizedSpecialCardContent: LocalizedSpecialCardContent | null = getLocalizedContent({ site, currentLocale, localizedContent: content?.localizedSpecialCard || [] });
            const requestParams: string = this.getRequestParams(localizedSpecialCardContent);
            const localizedContent: LocalizedContent | null = getLocalizedContent({ site, currentLocale, localizedContent: content?.localizedContent || [] });
            const localizedCardContent: LocalizedCardContent | null = getLocalizedContent({ site, currentLocale, localizedContent: content?.localizedCardContent || [] });
            const localizedTitle: LocalizedContent | null = getLocalizedContent({ site, currentLocale, localizedContent: content?.title || [] });
            const title: string = localizedTitle?.content || content.name;
            let richTextDesc;
            if (localizedCardContent?.richTextDesc?.startsWith('{"blocks"') || localizedCardContent?.richTextDesc?.startsWith('{"entityMap"')) {
                richTextDesc = draftToHtml(JSON.parse(localizedCardContent?.richTextDesc));
            } else {
                richTextDesc = localizedCardContent?.richTextDesc;
            }
            let html: string;
            if (localizedContent?.content) {
                const contentStr = localizedContent.content;
                if (contentStr.startsWith('{"blocks"') || contentStr.startsWith('{"entityMap"')) {
                    try {
                        const contentRaw = JSON.parse(contentStr);
                        html = content!.isRawHtmlWidget ? convertFromRaw(contentRaw).getPlainText() : draftToHtml(contentRaw);
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.error("static content parse error", contentStr, e);
                        html = getI18nLocaleString(namespaceList.widgetAssetPublisher, "failedToLoadContent", currentLocale, site);
                    }
                } else {
                    html = contentStr;
                }
            } else {
                html = getI18nLocaleString(namespaceList.widgetAssetPublisher, "noContent", currentLocale, site);
            }
            const sitePageUrl = content.sitePageUrl;
            let imageUrl = "";
            if (this.state.assetsImages?.length) {
                const assetImage = this.state.assetsImages.find((asset) => (asset ? asset.assetId : "") === content._id);
                imageUrl = assetImage?.imageUrl || "";
            }
            const modelClasses = findMultiSelectStyleClassNames(theme, MODEL_TARGETS, content ? content.styleIds : []);

            const renderLink = () => (
                <React.Fragment>
                    {localizedCardContent ? (
                        !localizedCardContent.isSitePageUrl && sitePageUrl ? (
                            <SmartLink
                                href={sitePageUrl}
                                title={title}
                                target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                className={classNames({ "card-block-link": localizedCardContent.clickOnCard })}
                            >
                                {!localizedCardContent?.clickOnCard && localizedCardContent?.cardButton}
                            </SmartLink>
                        ) : (
                            localizedCardContent.isSitePageUrl &&
                            localizedCardContent.externalLink && (
                                <SmartLink
                                    className={classNames({ "text-link card-block-link": localizedCardContent.clickOnCard })}
                                    href={localizedCardContent.externalLink}
                                    title={title}
                                    target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                    rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                >
                                    {!localizedCardContent?.clickOnCard && localizedCardContent?.cardButton}
                                </SmartLink>
                            )
                        )
                    ) : null}
                    {"permalink" in content && "postClickOnCard" in content ? (
                        <SmartLink
                            className={classNames("text-link card-block-link", { "squared-card__button": options.squaredCards })}
                            href={this.getCurrentLocaleUrl(content.permalink || "")}
                            title={content.name}
                            target={content?.openInNewTab ? "_blank" : "_self"}
                        />
                    ) : (
                        "permalink" in content && (
                            <SmartLink
                                className={classNames({ "squared-card__button": options.squaredCards })}
                                href={this.getCurrentLocaleUrl(content.permalink || "")}
                                title={content.name}
                                target={content?.openInNewTab ? "_blank" : "_self"}
                            >
                                {getI18nLocaleString(namespaceList.widgetAssetPublisher, "moreLink", currentLocale, site)}
                            </SmartLink>
                        )
                    )}
                </React.Fragment>
            );

            if (options.layoutType === LayoutType.CARD) {
                return (
                    <Card key={content._id} className="asset-cards">
                        <div className={`simple-card-wrapper ${modelClasses}`}>
                            <div className="asset-card-inner layout layout--xxs">
                                <div
                                    // eslint-disable-next-line max-len
                                    className={`layout__item ${localizedCardContent?.clickOnCard ? "clickable-card" : ""}`}
                                >
                                    <div className={classNames("card-wrap", { "vertical-card": options.showVerticalCards })}>
                                        {content && (content as WebcontentWithId).cardEnabled && (content as WebcontentWithId).cardSpecial && (
                                            <div className="special-card-box">
                                                <div className="special-card-title">
                                                    <div>{`${localizedSpecialCardContent?.cardSpecialTitle}`}</div>
                                                    <div>{localizedSpecialCardContent?.cardSpecialDiscount}</div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="card-image-wrapper">
                                            <img
                                                loading="lazy"
                                                srcSet={`${this.createMobileImage(imageUrl)} 300w, ${this.createMobileImage(imageUrl)} 768w, ${imageUrl} 1280w`}
                                                sizes={"(min-width: 720px) calc(94.76vw - 274px), (min-width: 520px) calc(100vw - 96px), calc(100vw - 32px)"}
                                                src={imageUrl}
                                                alt={localizedCardContent?.cardTitle || content.name}
                                            />
                                        </div>
                                        <CardBody
                                            // eslint-disable-next-line max-len
                                            className={`background-neutral-10 space-p ${options.containerType === "masonry" ? "" : "equalcards"} ${
                                                localizedCardContent?.isSitePageUrl || (localizedCardContent?.siteId && localizedCardContent.pageId) ? "btn-added" : ""
                                            }`}
                                        >
                                            {localizedCardContent?.cardTitle ? <CardTitle tag="h4">{localizedCardContent.cardTitle}</CardTitle> : null}
                                            {localizedCardContent?.cardSubTitle ? <CardSubtitle>{localizedCardContent.cardSubTitle}</CardSubtitle> : null}
                                            {localizedCardContent?.location || localizedCardContent?.startDate ? (
                                                <div className="date-location">
                                                    {localizedCardContent.location ? (
                                                        <span className="location space-mr-xs">
                                                            <FontAwesome name="map-marker" />
                                                            <span className="location-name">{localizedCardContent.location}</span>
                                                        </span>
                                                    ) : null}
                                                    {localizedCardContent.startDate ? (
                                                        <div
                                                            // eslint-disable-next-line max-len
                                                            className={`dates ${localizedCardContent.endDate ? "space-mt-xxs" : "single-date"}`}
                                                        >
                                                            <span>
                                                                <FontAwesome name="calendar" />
                                                                <span className="date">{localizedCardContent.startDate.toString().substr(0, 10).split("-").reverse().join("-")}</span>
                                                            </span>
                                                            {localizedCardContent.endDate ? (
                                                                // eslint-disable-next-line max-len
                                                                <span className="date">
                                                                    {` - ${localizedCardContent && localizedCardContent.endDate.toString().substr(0, 10).split("-").reverse().join("-")}`}
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                            {localizedCardContent?.cardDescription ? (
                                                <CardText className="space-mb-0">{localizedCardContent.cardDescription}</CardText>
                                            ) : (
                                                localizedCardContent && richTextDesc && <div dangerouslySetInnerHTML={{ __html: richTextDesc }} />
                                            )}
                                            <div className="lower-panel">
                                                {localizedSpecialCardContent?.cardOriginalPrice && (
                                                    <div className="original-price">
                                                        <span>{getI18nLocaleString(namespaceList.widgetTypeSearch, "from", currentLocale, site)}</span>
                                                        <span className="pl-2">{localizedSpecialCardContent.cardOriginalPrice}</span>
                                                    </div>
                                                )}
                                                {localizedSpecialCardContent?.cardSpecialPrice && <h4 className="special-price m-0">{localizedSpecialCardContent.cardSpecialPrice}</h4>}
                                                {localizedSpecialCardContent?.cardSpecialPrice && <i className="fa fa-question-circle question-mark" aria-hidden="true" />}
                                                {localizedSpecialCardContent?.priceDescription && <div className="price-description-popover">{localizedSpecialCardContent.priceDescription}</div>}
                                                {localizedSpecialCardContent?.startDate && <label className="start-date">{localizedSpecialCardContent.startDate}</label>}
                                                {localizedSpecialCardContent?.rentability && <p className="rentability">{localizedSpecialCardContent.rentability}</p>}
                                            </div>
                                            {
                                                <div className="card-button-link">
                                                    {((localizedCardContent?.externalLink && localizedCardContent.isSitePageUrl === undefined) || localizedCardContent?.isSitePageUrl) &&
                                                    localizedCardContent.cardButton ? (
                                                        <SmartLink
                                                            className={"button button--l button--primary button--full"}
                                                            // eslint-disable-next-line max-len
                                                            href={`${localizedCardContent.externalLink}${requestParams ? "?" + requestParams : ""}`}
                                                            target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                                            rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                                            title={`${localizedCardContent.cardTitle || localizedCardContent.cardButton}`}
                                                        >
                                                            {localizedCardContent.cardButton}
                                                        </SmartLink>
                                                    ) : sitePageUrl && localizedCardContent?.cardButton ? (
                                                        <SmartLink
                                                            className={"button button--l button--primary button--full"}
                                                            // eslint-disable-next-line max-len
                                                            href={`${sitePageUrl}${requestParams ? "?" + requestParams : ""}`}
                                                            target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                                            rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                                            title={`${localizedCardContent.cardTitle || localizedCardContent.cardButton}`}
                                                        >
                                                            {localizedCardContent.cardButton}
                                                        </SmartLink>
                                                    ) : null}
                                                </div>
                                            }
                                        </CardBody>
                                        {(localizedCardContent?.isSitePageUrl && !localizedCardContent.externalLink) ||
                                        (!localizedCardContent?.isSitePageUrl && sitePageUrl && localizedCardContent?.clickOnCard) ? (
                                            <SmartLink
                                                className={"card-block-link"}
                                                // eslint-disable-next-line max-len
                                                href={`${sitePageUrl}${requestParams ? "?" + requestParams : ""}`}
                                                target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                                rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                                title={`${localizedCardContent.cardTitle || localizedCardContent.cardButton}`}
                                            />
                                        ) : localizedCardContent?.externalLink && localizedCardContent.clickOnCard ? (
                                            <SmartLink
                                                className={"card-block-link"}
                                                href={`${localizedCardContent.externalLink}`}
                                                target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                                rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                                title={`${localizedCardContent.cardTitle || localizedCardContent.cardButton}`}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                );
            } else if (options.layoutType === LayoutType.REVEALER) {
                return (
                    <Card key={content._id} className="layout__item asset-revealer-cards">
                        <CardBody className="assets-revealer-wrap">
                            <div className="front background-neutral-10">
                                <div className="space-mr-0">
                                    <img
                                        loading="lazy"
                                        srcSet={`${this.createMobileImage(imageUrl)} 300w, ${this.createMobileImage(imageUrl)} 768w, ${imageUrl} 1280w`}
                                        sizes={"(min-width: 720px) calc(94.76vw - 274px), (min-width: 520px) calc(100vw - 96px), calc(100vw - 32px)"}
                                        src={imageUrl}
                                        alt={localizedCardContent?.cardTitle || content.name}
                                    />
                                </div>
                                <div className="space-p-s">
                                    {localizedCardContent?.cardTitle ? <CardTitle tag="h4">{localizedCardContent.cardTitle}</CardTitle> : null}
                                    {localizedCardContent?.cardSubTitle ? <CardSubtitle>{localizedCardContent.cardSubTitle}</CardSubtitle> : null}
                                    {localizedCardContent?.location || localizedCardContent?.startDate ? (
                                        <div className="date-location">
                                            {localizedCardContent.location ? (
                                                <span className="location space-mr-xs">
                                                    <FontAwesome name="map-marker" />
                                                    <span className="location-name">{localizedCardContent.location}</span>
                                                </span>
                                            ) : null}
                                            {localizedCardContent.startDate ? (
                                                <div
                                                    // eslint-disable-next-line max-len
                                                    className={`dates ${localizedCardContent.endDate ? "space-mt-xxs" : "single-date"}`}
                                                >
                                                    <FontAwesome name="calendar" />
                                                    <span className="date">{localizedCardContent.startDate.toString().substr(0, 10).split("-").reverse().join("-")}</span>
                                                    {localizedCardContent.endDate ? (
                                                        // eslint-disable-next-line max-len
                                                        <span className="date">{` - ${localizedCardContent.endDate.toString().substr(0, 10).split("-").reverse().join("-")}`}</span>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : null}
                                    {localizedCardContent?.cardDescription ? (
                                        <CardText>{localizedCardContent.cardDescription}</CardText>
                                    ) : (
                                        localizedCardContent && richTextDesc && <div dangerouslySetInnerHTML={{ __html: richTextDesc }} />
                                    )}
                                </div>
                                <div className="read-more-link">
                                    <a>{getI18nLocaleString(namespaceList.widgetAssetPublisher, "readMoreLink", currentLocale, site)}</a>
                                </div>
                            </div>
                            <div className="back">
                                <div className="assets-desc space-p">
                                    <div className="description space-mb-m" dangerouslySetInnerHTML={{ __html: html }} />
                                    {((localizedCardContent?.externalLink && localizedCardContent.isSitePageUrl === undefined) || !localizedCardContent?.isSitePageUrl) &&
                                    sitePageUrl &&
                                    localizedCardContent?.cardButton ? (
                                        <SmartLink
                                            className={"button button--l button--primary button--full"}
                                            href={`${localizedCardContent && sitePageUrl}`}
                                            target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                            rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                            // eslint-disable-next-line max-len
                                            title={`${localizedCardContent.cardTitle || localizedCardContent.cardButton}`}
                                        >
                                            {localizedCardContent.cardButton}
                                        </SmartLink>
                                    ) : localizedCardContent?.externalLink && localizedCardContent.cardButton ? (
                                        <SmartLink
                                            className={"button button--l button--primary button--full"}
                                            href={`${localizedCardContent.externalLink}`}
                                            target={localizedCardContent.openNewTab ? "_blank" : "_self"}
                                            rel={localizedCardContent.isSitePageUrl && localizedCardContent.noFollowLink ? "nofollow" : undefined}
                                            // eslint-disable-next-line max-len
                                            title={`${localizedCardContent.cardTitle || localizedCardContent.cardButton}`}
                                        >
                                            {localizedCardContent.cardButton}
                                        </SmartLink>
                                    ) : null}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                );
            } else if (options.layoutType === LayoutType.ACCORDION) {
                return (
                    <div key={content._id} className={"accordion-wrapper"}>
                        <section className={`toggle ${hideWidget} ${className}`}>
                            <div className={`reveal-link-wrapper ${options.iconToRight && "right-reveal"}`}>
                                <a className={"toggle-link"} onClick={this.toggle(content._id)}>
                                    {options.plusIcon && !options.iconToRight && (content.isExpanded ? showLessIcon : showMoreIcon)}
                                    <span className="toggle-text">{title}</span>
                                    {options.plusIcon && options.iconToRight && (content.isExpanded ? showLessIcon : showMoreIcon)}
                                </a>
                            </div>
                            <Collapse isOpen={content.isExpanded}>
                                <div className="items">
                                    <div className="description" dangerouslySetInnerHTML={{ __html: html }} />
                                </div>
                            </Collapse>
                        </section>
                    </div>
                );
            }
            if (options.squaredCards) {
                return (
                    <Card key={content._id} className={classNames("col-sm-12 col-12 col-md-6 col-lg-4")}>
                        <CardBody
                            className={classNames("squared-card", {
                                "clickable-card": localizedCardContent?.clickOnCard || "postClickOnCard" in content,
                            })}
                            style={{ backgroundImage: "url(" + imageUrl + ")" }}
                        >
                            <div className={classNames("squared-card__wrapper")}>
                                {!options.hideTitle && <div className="squared-card__title">{title}</div>}
                                {!options.hideDescription && <div className="squared-card__description" dangerouslySetInnerHTML={{ __html: html }} />}
                                <div className={classNames("squared-card__link")}>{renderLink()}</div>
                            </div>
                        </CardBody>
                    </Card>
                );
            }
            return (
                <Card key={content._id} className={classNames("layout__item space-mb-xxs border-0", { ["layout__slider"]: options.containerType === "slider" })}>
                    <CardBody
                        // eslint-disable-next-line max-len
                        className={classNames("assets-wrap", {
                            "clickable-card": localizedCardContent?.clickOnCard || "postClickOnCard" in content,
                        })}
                    >
                        <div className={classNames("card-wrap", { "vertical-card": options.showVerticalCards })}>
                            {imageUrl ? <div className="assets-img space-mr-0" style={{ backgroundImage: "url(" + imageUrl + ")" }} /> : <div />}
                            <div className={"assets-desc space-p"}>
                                {!options.hideTitle && <div className="h6-text space-mb-xs">{title}</div>}
                                {localizedCardContent?.cardTitle ? <CardTitle tag="h4">{localizedCardContent.cardTitle}</CardTitle> : null}
                                {localizedCardContent?.cardSubTitle ? <CardSubtitle>{localizedCardContent.cardSubTitle}</CardSubtitle> : null}
                                {localizedCardContent?.location || localizedCardContent?.startDate ? (
                                    <div className="date-location">
                                        {localizedCardContent.location ? (
                                            <span className="location space-mr-xs">
                                                <FontAwesome name="map-marker" />
                                                <span className="location-name">{localizedCardContent.location}</span>
                                            </span>
                                        ) : null}
                                        {localizedCardContent.startDate ? (
                                            <div className={`dates ${localizedCardContent.endDate ? "space-mt-xxs" : "single-date"}`}>
                                                <FontAwesome name="calendar" />
                                                <span className="date">{localizedCardContent.startDate.toString().substr(0, 10).split("-").reverse().join("-")}</span>
                                                {localizedCardContent.endDate ? (
                                                    <span className="date">
                                                        {` -
                                                                ${localizedCardContent.endDate.toString().substr(0, 10).split("-").reverse().join("-")}
                                                            `}
                                                    </span>
                                                ) : null}
                                            </div>
                                        ) : null}
                                    </div>
                                ) : null}
                                {localizedCardContent?.cardDescription ? <CardText>{localizedCardContent.cardDescription}</CardText> : null}
                                {richTextDesc ? <div className="card-text" dangerouslySetInnerHTML={{ __html: richTextDesc }} /> : null}
                                {!options.hideDescription && <div className="description" dangerouslySetInnerHTML={{ __html: html }} />}
                                {renderLink()}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            );
        });
        return (
            <div
                key={"asset"}
                className={classNames("space-mb-s", hideWidget, className, {
                    "card-layout": options.layoutType === LayoutType.CARD,
                    "revealer-layout": options.layoutType === LayoutType.REVEALER,
                    "normal": options.containerType === "simpleCards",
                })}
            >
                {label && <h2>{label}</h2>}
                <div
                    className={classNames("assets-wrapper", options.containerType, {
                        "slider-wrap": options.containerType === "slider",
                        "normal-wrap": options.containerType !== "slider" && !options.squaredCards,
                        "no-gutters": options.assetsNoGutters,
                        "vertical-card-slideshow": (options.showVerticalCards && options.assetType === "Webcontent") || (options.showVerticalCards && options.assetType === "Any"),
                        "vertical-post-slideshow": options.showVerticalCards && options.assetType === "Posts",
                        "row": options.containerType !== "slider" && !options.squaredCards,
                        "normal-card": options.containerType !== "slider" && options.layoutType !== "card",
                        "layout layout--xxs": options.layoutType !== "card" && !options.squaredCards,
                    })}
                >
                    {options.containerType === "masonry" ? (
                        <MasonryWrappedWithChildrenProps breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                            {assetData}
                        </MasonryWrappedWithChildrenProps>
                    ) : options.containerType === "slider" ? (
                        <div className={classNames("slideshow-container slideshow-advanced", className)}>
                            <Swiper {...sliderParams} className="slideShow-wrapper">
                                {assetData}
                            </Swiper>
                        </div>
                    ) : (
                        assetData
                    )}
                </div>
            </div>
        );
    }

    private renderToggleIcon = (name: string) => {
        const { options } = this.props;
        const fontAwesomeClass: string = options.iconPropertiesForMore || options.iconPropertiesForLess ? `fontawesome-${name} icon` : "";
        return (
            <FontAwesome
                name={name || ""}
                className={`${fontAwesomeClass} ${options.iconToRight ? "ml-2" : "mr-1"} ${
                    options.iconColor && options.iconColor.includes("theme")
                        ? `color-${options.iconColor}`
                        : options.iconColor && !options.iconColor.includes("rgba")
                        ? "icon-" + options.iconColor
                        : ""
                }`}
                style={{ color: options.iconColor && options.iconColor.includes("rgba") ? options.iconColor : undefined }}
            />
        );
    };

    private createMobileImage(url: string): string {
        if (url) {
            return url.replace("t_mcms_original", "t_mcms_normal").replace("t_mcms_larger", "t_mcms_normal").replace("t_mcms_medium", "t_mcms_normal");
        }
        return url;
    }

    private toggle = (id: string) => () => {
        const { assets } = this.state;
        const { tabbedAssets } = this.props;
        const finalAssets = tabbedAssets?.length ? tabbedAssets : assets;

        const newAssets: IAsset[] = finalAssets?.map((asset: IAsset) => {
            if (asset._id === id) {
                return { ...asset, isExpanded: !asset.isExpanded };
            }
            return asset;
        });

        this.setState({
            assets: newAssets,
        });
    };

    private getRequestParams(localizedSpecialCardContent: LocalizedSpecialCardContent | null) {
        let requestParams = "";
        if (localizedSpecialCardContent?.addRequestParams) {
            if (localizedSpecialCardContent.addStartDate && localizedSpecialCardContent.startDate) {
                requestParams += "&startdate=" + localizedSpecialCardContent.startDate.split("-").reverse().join("");
            }
            if (localizedSpecialCardContent.addEndDate && localizedSpecialCardContent.endDate) {
                requestParams += "&enddate=" + localizedSpecialCardContent.endDate.split("-").reverse().join("");
            }
            if (localizedSpecialCardContent.stay) {
                requestParams += "&stay=" + localizedSpecialCardContent.stay;
            }
            if (localizedSpecialCardContent.amenities) {
                requestParams += "&amenity=" + localizedSpecialCardContent.amenities;
            }
            if (localizedSpecialCardContent.resort) {
                requestParams += "&resort=" + localizedSpecialCardContent.resort;
            }
            if (localizedSpecialCardContent.resourceId) {
                requestParams += "&resourceid=" + localizedSpecialCardContent.resourceId;
            }
            if (localizedSpecialCardContent.specialCode) {
                requestParams += "&specialcode=" + localizedSpecialCardContent.specialCode;
            }
        }
        return requestParams;
    }

    private getCurrentLocaleUrl(permalink: string): string {
        const { context } = this.props;
        let url = "";
        const permalinkSplitArray = permalink.split("/");
        const urlTermsArray = permalinkSplitArray.filter((element) => element);
        const friendlyUrlArray = urlTermsArray.slice(1);
        const chunk = friendlyUrlArray.length === 1 ? friendlyUrlArray[0] : friendlyUrlArray.join("/");
        url = "//";
        url = url.concat(context.site.host);
        // eslint-disable-next-line max-len
        url = url.concat(`${context.site.enableMultiLanguage && context.site.locale.code !== context.currentLocale.code ? "/" + context.currentLocale.code : ""}`);
        url = url.concat("/", chunk);
        return url;
    }
}

function mapStateToProps(state: State): AssetPublisherStoreProps {
    return {
        myEnvState: state.myEnvState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction | MyEnvReducerAction>): AssetPublisherDispatchProps {
    return { dispatchAction: dispatch };
}

const AssetPublisherBaseType = connect<AssetPublisherStoreProps, AssetPublisherDispatchProps>(mapStateToProps, mapDispatchToProps)(AssetPublisherBase);

export const AssetPublisher = withTabbedAssets(wrapProps<AssetPublisherPropsBase>(CMSAware<AssetPublisherPropsBase>(withEditModal(AssetPublisherBaseType))));
