import * as React from "react";

import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";

import { AssetPublisherPropsBase } from "./AssetPublisher";
import { IAsset } from "./";
import { Tag } from "@maxxton/cms-api";

interface GroupedAssets {
    tag: number;
    assets: IAsset[];
    text: string;
}

export const withTabbedAssets = <P extends object>(Component: React.ComponentType<P>) => {
    const WrappedComponent = (props: AssetPublisherPropsBase) => {
        const [tabbedAssets, setTabbedAssets] = React.useState<GroupedAssets[]>();
        const [activeTab, setActiveTab] = React.useState<string>("1");
        const groupAssets = (resortIds?: number[], assets?: IAsset[]): GroupedAssets[] => {
            const groups = resortIds
                ?.map((resortId) => {
                    const matchingAssets = assets?.filter((asset) => asset?.tags?.some((tag: Tag) => tag?.id === resortId));
                    if (matchingAssets?.length) {
                        const text = matchingAssets?.[0]?.tags.find((tag) => tag.id === resortId)?.text || "";
                        return { tag: resortId, assets: matchingAssets, text };
                    }
                    return null;
                })
                .filter((group): group is GroupedAssets => !!group);

            return groups || [];
        };

        React.useEffect(() => {
            const { assets, ownerResortIds } = props;
            if (ownerResortIds?.length) {
                const content = groupAssets(ownerResortIds, assets);
                setTabbedAssets(content);
            }
        }, [activeTab]);

        const toggleTab = (tab: string) => {
            if (activeTab !== tab) {
                setActiveTab(tab);
            }
        };

        return tabbedAssets?.length ? (
            <React.Fragment>
                <Nav tabs>
                    <Row>
                        {tabbedAssets?.map((asset, index) => (
                            <Col sm="auto" key={index}>
                                <NavItem>
                                    <NavLink onClick={() => toggleTab((index + 1)?.toString())}>{asset?.text}</NavLink>
                                </NavItem>
                            </Col>
                        ))}
                    </Row>
                </Nav>

                <TabContent activeTab={activeTab}>
                    {tabbedAssets?.map((asset, index) => (
                        <TabPane tabId={(index + 1)?.toString()} key={index}>
                            <Row>
                                <Col sm="12">
                                    <Component tabbedAssets={asset.assets} {...(props as any)} />
                                </Col>
                            </Row>
                        </TabPane>
                    ))}
                </TabContent>
            </React.Fragment>
        ) : (
            <Component {...(props as any)} />
        );
    };
    WrappedComponent.displayName = "WithTabbedAssets";
    return WrappedComponent;
};
