import * as Icons from "../../page/icons";
import * as React from "react";

import { FormSpec, IconColoring, localized, multiSelectSpec, multiSelectStylePicker, tagList } from "../../../form-specs";
import { LocalizedContentBase, Post, Site, WebContent, WithId } from "@maxxton/cms-api";
import { PageWidgetSpec, Widget } from "../../";
import { allSites, contentTypeSelector } from "../../../components/utils";
import { getAssetsAndAssetsImages, getLocalizedLabel, getUniqueResortIdsForOwner } from "./assetPublisher.util";

import { AssetPublisher } from "./AssetPublisher";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { WidgetGroup } from "../../widget.enum";
import { categorySpec } from "../../../form-specs/models/category";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface LocalizedAssetPublisherOptions extends LocalizedContentBase {
    label: string;
}

export interface WidgetOptions {
    localized: LocalizedAssetPublisherOptions[];
    assetType: AssetType;
    containrule: ContainRule;
    anyrule: AnyRule;
    orderbyrule: OrderByRule;
    ordertyperule: OrderTypeRule;
    tagList: any[];
    categoryList: any[];
    limit: number;
    layoutType: string;
    assetsNoGutters: boolean;
    containerType: string;
    slidesPerView: number;
    slideBreakpoints: boolean;
    slidesResponsiveDesktop: number;
    slidesResponsiveTablet: number;
    slidesResponsiveMobLandscape: number;
    slidesResponsiveMobPortrait: number;
    iconToRight: boolean;
    plusIcon: boolean;
    styleIds: string[];
    iconColor: IconColoring;
    iconPropertiesForMore: string;
    iconPropertiesForLess: string;
    showVerticalCards?: boolean;
    hideDescription?: boolean;
    hideTitle?: boolean;
    sneakPreview?: boolean;
    spaceBetween: number;
    slideLoop?: boolean;
    squaredCards?: boolean;
    enableLocationForOwner?: boolean;
    contentType?: string;
}

export interface IAsset extends Post, WebContent, WithId {
    isExpanded?: boolean;
    sitePageUrl?: string;
}

export interface IconSelect {
    value: string;
    label: string;
}

export interface DefaultIcons {
    [key: string]: string;
}

type AssetType = "Any" | "Posts" | "Webcontent";
type ContainRule = "Contains" | "Does not Contain";
type AnyRule = "Any" | "All";
type OrderByRule = "Create Date" | "Modified Date" | "Publish Date" | "Event Date";
type OrderTypeRule = "Descending" | "Ascending";

const TARGETS = ["revealer-link", "assetpublisher"];
const iconList: IconSelect[] = Object.keys(Icons.default).map((key: string) => ({ label: (Icons.default as DefaultIcons)[key], value: key }));
export const orderByToFieldMap: { [rule in OrderByRule]: string } = {
    "Create Date": "createdAt",
    "Modified Date": "updatedAt",
    "Publish Date": "publishDate",
    "Event Date": "createdAt", // This is for posts, wrote custom orderRule below for webcontents
};

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "group-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "assetPublisherWidget"),
    pluralName: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "assetPublisherWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "label",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "label"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                variable: "assetType",
                                label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "assetType"),
                                type: "select",
                                default: "Any" as AssetType,
                                optionList: [
                                    { value: "Any", label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "any") },
                                    { value: "Posts", label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "posts") },
                                    {
                                        value: "Webcontent",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "webcontent"),
                                    },
                                ],
                            },
                            {
                                variable: "containrule",
                                label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "ruletext"),
                                type: "select",
                                default: "Contains" as ContainRule,
                                optionList: [
                                    {
                                        value: "Contains",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "contain"),
                                    },
                                    {
                                        value: "Does not Contain",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "donotcontain"),
                                    },
                                ],
                            },
                            {
                                variable: "anyrule",
                                type: "select",
                                default: "Any" as AnyRule,
                                optionList: [
                                    {
                                        value: "Any",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "any"),
                                    },
                                    {
                                        value: "All",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "all"),
                                    },
                                ],
                            },
                            // eslint-disable-next-line max-len
                            multiSelectSpec("tagList", getI18nLocaleObject(namespaceList.widgetAssetPublisher, "followinglable"), true, tagList),
                            {
                                ...contentTypeSelector("contentType", true, true, getI18nLocaleObject(namespaceList.widgetAssetPublisher, "dynamicField")),
                                visible: (options: WidgetOptions) => !options?.enableLocationForOwner,
                            },
                            {
                                variable: "enableLocationForOwner",
                                label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "enableLocationForOwner"),
                                type: "checkbox",
                                default: false,
                            },
                            {
                                variable: "categoryList",
                                type: "category",
                                refType: categorySpec,
                            },
                            {
                                variable: "orderbyrule",
                                label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "orderby"),
                                type: "select",
                                default: "Modified Date" as OrderByRule,
                                // Show options based on Posts or Webcontent
                                optionList: [
                                    {
                                        value: "Create Date",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "createdate"),
                                    },
                                    {
                                        value: "Modified Date",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "modifieddate"),
                                    },
                                    {
                                        value: "Publish Date",
                                        label: getI18nLocaleObject(namespaceList.admin, "publishDate"),
                                    },
                                    {
                                        value: "Event Date",
                                        label: getI18nLocaleObject(namespaceList.admin, "eventDate"),
                                        visible: (options: WidgetOptions) => options.assetType === "Webcontent" || options.assetType === "Any",
                                    },
                                ],
                            },
                            {
                                variable: "ordertyperule",
                                type: "select",
                                default: "Descending" as OrderTypeRule,
                                optionList: [
                                    {
                                        value: "Descending",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "descending"),
                                    },
                                    {
                                        value: "Ascending",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "ascending"),
                                    },
                                ],
                            },
                            {
                                variable: "limit",
                                label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "limit"),
                                type: "number",
                                default: 4,
                            },
                            {
                                variable: "layoutType",
                                label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "layoutType"),
                                visible: (options: WidgetOptions) => options.assetType === "Webcontent",
                                type: "select",
                                default: "normal",
                                optionList: [
                                    {
                                        value: "normal",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "normal"),
                                    },
                                    {
                                        value: "card",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "card"),
                                    },
                                    {
                                        value: "revealer",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "revealer"),
                                    },
                                    {
                                        value: "useAsAccordion",
                                        label: getI18nLocaleObject(namespaceList.widgetRevealer, "useAsAccordion"),
                                    },
                                ],
                            },
                            {
                                variable: "assetsNoGutters",
                                label: getI18nLocaleObject(namespaceList.widgetFlexbox, "noGutters"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => options.layoutType === "card" && options.containerType !== "slider",
                            },
                            {
                                variable: "containerType",
                                label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "containerType"),
                                type: "select",
                                default: "simpleCards",
                                visible: (options: WidgetOptions) => options.layoutType !== "useAsAccordion",
                                optionList: [
                                    {
                                        value: "masonry",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "masonry"),
                                        visible: (options: WidgetOptions) => options.layoutType === "card",
                                    },
                                    {
                                        value: "slider",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "slider"),
                                    },
                                    {
                                        value: "simpleCards",
                                        label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "simpleCards"),
                                    },
                                ],
                            },
                            {
                                variable: "squaredCards",
                                label: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "squaredCards"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => options.containerType === "simpleCards",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "showVerticalCards"),
                                variable: "showVerticalCards",
                                type: "checkbox",
                                visible: (options: WidgetOptions): boolean => options.containerType === "slider",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "hideDescription"),
                                variable: "hideDescription",
                                type: "checkbox",
                                visible: (options: WidgetOptions): boolean => options.containerType === "slider",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "hideTitle"),
                                variable: "hideTitle",
                                type: "checkbox",
                                visible: (options: WidgetOptions): boolean => options.containerType === "slider",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "sneakPreview"),
                                variable: "sneakPreview",
                                type: "checkbox",
                                visible: (options: WidgetOptions): boolean => options.containerType === "slider",
                            },
                            {
                                variable: "slideLoop",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideLoop"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.containerType === "slider",
                            },
                            {
                                variable: "spaceBetween",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "spaceBetween"),
                                type: "number",
                                visible: (options: WidgetOptions): boolean => options.containerType === "slider",
                            },
                            {
                                // plusIcon variable will be use as show an icon
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "iconTitle"),
                                variable: "plusIcon",
                                type: "checkbox",
                                visible: (options: WidgetOptions): boolean => options.layoutType === "useAsAccordion",
                            },
                            {
                                variable: "iconPropertiesForLess",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "iconForLess"),
                                type: "select",
                                visible: (options: WidgetOptions): boolean => options.plusIcon,
                                optionList: iconList,
                            },
                            {
                                variable: "iconPropertiesForLess",
                                type: "icons",
                                visible: (options: WidgetOptions): boolean => options.plusIcon,
                            },
                            {
                                variable: "iconPropertiesForMore",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "iconForMore"),
                                type: "select",
                                visible: (options: WidgetOptions): boolean => options.plusIcon,
                                optionList: iconList,
                            },
                            {
                                variable: "iconPropertiesForMore",
                                type: "icons",
                                visible: (options: WidgetOptions): boolean => options.plusIcon,
                            },
                            {
                                variable: "iconColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconColor"),
                                type: "dual-color",
                                default: "default",
                                visible: (options: WidgetOptions): boolean => options.plusIcon,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "iconToRight"),
                                variable: "iconToRight",
                                type: "checkbox",
                                visible: (options: WidgetOptions): boolean => options.plusIcon,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetSlideShow, "ResponsiveSetting"),
                    properties: [
                        [
                            {
                                variable: "slidesPerView",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slidesPerView"),
                                type: "number",
                                visible: (options: WidgetOptions) => options.containerType === "slider",
                            },
                            {
                                variable: "slideBreakpoints",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slideBreakpoints"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.containerType === "slider",
                            },
                            {
                                variable: "slidesResponsiveDesktop",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slidesResponsiveDesktop"),
                                type: "number",
                                visible: (option: WidgetOptions) => option.slideBreakpoints,
                            },
                            {
                                variable: "slidesResponsiveTablet",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slidesResponsiveTablet"),
                                type: "number",
                                visible: (option: WidgetOptions) => option.slideBreakpoints,
                            },
                            {
                                variable: "slidesResponsiveMobLandscape",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slidesResponsiveMobLandscape"),
                                type: "number",
                                visible: (option: WidgetOptions) => option.slideBreakpoints,
                            },
                            {
                                variable: "slidesResponsiveMobPortrait",
                                label: getI18nLocaleObject(namespaceList.widgetSlideShow, "slidesResponsiveMobPortrait"),
                                type: "number",
                                visible: (option: WidgetOptions) => option.slideBreakpoints,
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

export const assetPublisherWidget: PageWidgetSpec<WidgetOptions> = {
    id: "assetpublisher",
    type: "page",
    widgetGroup: WidgetGroup.CONTENT,
    name: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "assetPublisherWidget"),
    description: getI18nLocaleObject(namespaceList.widgetAssetPublisher, "assetPublisherWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        localized: [],
        assetType: "Any",
        containrule: "Contains",
        anyrule: "Any",
        orderbyrule: "Modified Date",
        ordertyperule: "Descending",
        tagList: [],
        categoryList: [],
        limit: 4,
        layoutType: "normal",
        assetsNoGutters: false,
        containerType: "simpleCards",
        slidesPerView: 2,
        slideBreakpoints: false,
        slidesResponsiveDesktop: 2,
        slidesResponsiveTablet: 2,
        slidesResponsiveMobLandscape: 2,
        slidesResponsiveMobPortrait: 2,
        iconToRight: false,
        plusIcon: false,
        styleIds: [],
        iconColor: "color-brand",
        iconPropertiesForMore: "",
        iconPropertiesForLess: "",
        showVerticalCards: false,
        hideDescription: false,
        hideTitle: false,
        sneakPreview: false,
        spaceBetween: 10,
        slideLoop: false,
    }),
    // eslint-disable-next-line max-lines-per-function
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties) {
        const label = getLocalizedLabel(widget.options.localized, context);
        let uniqueResortIds: number[] = [];
        if (widget?.options?.enableLocationForOwner) {
            uniqueResortIds = await getUniqueResortIdsForOwner(context);
        }

        const { assets = [], assetsImages = [] } = await getAssetsAndAssetsImages(widget.options, context, uniqueResortIds);
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        const sites: Array<Site & WithId> = allSites.length ? allSites : await context.cmsApi.siteApi.find({ projection: { sitemap: 0 } });
        return (
            <AssetPublisher
                options={widget.options}
                label={label}
                assets={assets}
                assetsImages={assetsImages}
                context={context}
                className={styleClasses}
                allSites={sites}
                widgetOptionsForm={widgetOptionsForm}
                widgetId={widget._id}
                ownerResortIds={uniqueResortIds}
            />
        );
    },
};
