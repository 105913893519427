import * as moment from "moment";

import { CMSProvidedProperties, CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { IAsset, LocalizedAssetPublisherOptions, WidgetOptions, orderByToFieldMap } from "./";
import { Image, ImageSpec, findImagesBySpecs } from "../../../media";
import { LocalizedCardContent, Order, Post, Query, WebContent, WithId } from "@maxxton/cms-api";
import { checkVisibilityOnPublishExpirationDate, isClientLoggedIn } from "../../../components/utils";

import { getCMSOptions } from "../../settings";
import { getCustomerIdsFromLoginToken } from "../../../redux/reducers/myEnv/myEnv.util";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";
import { isClientSide } from "../../../utils/generic.util";
import { pageLink } from "../../../routing";

type AssetsType = (Post & WithId) | (WebContent & WithId);

export interface AssetsImage {
    imageUrl: string;
    assetId: string;
}

async function filterAssetsBasedOnPublishExpiration(assets: AssetsType[], context: CMSProvidedProperties): Promise<any[]> {
    const cmsOptions = isClientSide() ? (window as any).cmsOptions || (await getCMSOptions(context.cmsApi)) : await getCMSOptions(context.cmsApi);
    const siteTimeZone = context.site.timezoneList || "";
    const cmsTimeZone = cmsOptions && cmsOptions.timezoneList ? cmsOptions.timezoneList : "";
    const clientLoggedInOnAdmin = !isClientLoggedIn();
    return assets.filter((asset) => checkVisibilityOnPublishExpirationDate(clientLoggedInOnAdmin, cmsTimeZone, siteTimeZone, asset));
}

function getCategoryList(widgetOptions: WidgetOptions): number[] {
    if (widgetOptions.categoryList) {
        return [...widgetOptions.categoryList];
    }
    return [];
}

function getTagList(widgetOptions: WidgetOptions): string[] {
    return widgetOptions.tagList.map((tag) => tag.value);
}

export async function getAssetsAndAssetsImages(
    widgetOptions: WidgetOptions,
    context: CMSProvidedProperties,
    ownerResortIds?: number[]
): Promise<{
    assets: IAsset[];
    assetsImages: AssetsImage[];
}> {
    let assets: IAsset[] = [];
    const orderType = widgetOptions.ordertyperule === "Descending" ? -1 : 1;
    const order: Order = { [orderByToFieldMap[widgetOptions.orderbyrule]]: orderType };
    const limit: string = widgetOptions.limit ? widgetOptions.limit + "" : "4";
    const query: Query = getQuery(widgetOptions, context, ownerResortIds);
    if (widgetOptions.assetType === "Posts") {
        assets = await filterAssetsBasedOnPublishExpiration(await context.cmsApi.postApi.find({ query, order }), context);
        assets = assets.slice(0, parseInt(limit, 10));
    } else if (widgetOptions.assetType === "Webcontent") {
        if (widgetOptions.orderbyrule !== "Event Date") {
            // If not sorted by event date, do the normal sorting
            assets = await filterAssetsBasedOnPublishExpiration(await context.cmsApi.webContentApi.find({ query, order }), context);
        } else {
            assets = await sortWebContents(query, orderType, context);
        }
        assets = assets.slice(0, parseInt(limit, 10));
    } else if (widgetOptions.assetType === "Any") {
        let webcontents: IAsset[];
        if (widgetOptions.orderbyrule !== "Event Date") {
            webcontents = await filterAssetsBasedOnPublishExpiration(await context.cmsApi.webContentApi.find({ query, order }), context);
        } else {
            webcontents = await sortWebContents(query, orderType, context);
        }
        const posts: IAsset[] = await filterAssetsBasedOnPublishExpiration(await context.cmsApi.postApi.find({ query, order }), context);
        assets.push(...webcontents);
        assets.push(...posts);
        assets = assets.slice(0, parseInt(limit, 10));
    }
    let assetsImages: any[] = [];
    await Promise.all(
        assets.map(async (content: IAsset) => {
            if (content) {
                if ("cardEnabled" in content && content.cardEnabled) {
                    const localCardContent = content ? content.localizedCardContent.find((lc) => lc.locale === context.currentLocale.locale) : null;
                    if (localCardContent) {
                        assetsImages.push({
                            imageSpec: (localCardContent as any).cardImage,
                            assetId: content._id,
                        });
                    }
                } else {
                    assetsImages.push({
                        imageSpec: content.image,
                        assetId: content._id,
                    });
                }
                let sitePageUrl = "";
                const { currentLocale, site } = context;
                const localizedCardContent: LocalizedCardContent | null = getLocalizedContent({ site, currentLocale, localizedContent: content?.localizedCardContent || [] });
                const sites = await context.cmsApi.siteApi.find({ projection: { sitemap: 0 } });
                if (sites?.length && localizedCardContent?.siteId && localizedCardContent.pageId) {
                    sitePageUrl = sitePageUrl.concat("//");
                    const site = sites.find((item) => item._id === localizedCardContent.siteId);
                    if (site) {
                        sitePageUrl = sitePageUrl.concat(site.host);
                        const url = await pageLink({ site, pageId: localizedCardContent.pageId, locale: currentLocale, context });
                        content.sitePageUrl = sitePageUrl.concat(url ? url : "");
                    }
                }
            }
        })
    );
    if (assetsImages?.length) {
        const specs = [].concat.apply(
            [],
            assetsImages.map((assetImage) => assetImage.imageSpec)
        );
        const images: Image[] = await findImagesBySpecs(context, specs);
        assetsImages = assetsImages.map((asset) => {
            if (asset.imageSpec) {
                const imageSpec: ImageSpec = asset.imageSpec[0];
                const foundImage = imageSpec && images.find((image) => image.id === imageSpec.imageId);
                const imageUrl = foundImage ? foundImage.renderArrayUrl("medium", imageSpec.options.width, imageSpec.options.height) : "";
                return {
                    assetId: asset.assetId,
                    imageUrl,
                };
            }
        });
    }
    return {
        assets,
        assetsImages,
    };
}

const obtainRequiredIds = (contentType?: string, context?: CMSProvidedProperties) => {
    const dynamicFilter = context?.reduxStore?.store.getState().dynamicFilter;
    let params;
    switch (contentType) {
        case "resort":
            params = dynamicFilter?.resortids;
            break;
    }
    return params;
};

function getDynamicTagsQuery(widgetOptions: WidgetOptions, context: CMSProvidedProperties, ownerResortIds?: number[]): Query {
    const { contentType, enableLocationForOwner } = widgetOptions;
    const params = enableLocationForOwner ? ownerResortIds : obtainRequiredIds(contentType, context);
    const type = enableLocationForOwner ? "resort" : contentType;
    let query: Query = {};
    if (type && params) {
        query = { $and: [{ "tags.id": { $in: Array.isArray(params) ? params : [params] } }, { "tags.dynamicType": { $in: [type] } }] };
    }
    return query;
}

function getQuery(widgetOptions: WidgetOptions, context: CMSProvidedProperties, ownerResortIds?: number[]): Query {
    let query: Query = {};
    const queryForDynamicTags = getDynamicTagsQuery(widgetOptions, context, ownerResortIds);
    if (widgetOptions.containrule === "Contains" && widgetOptions.anyrule === "Any") {
        if (widgetOptions.categoryList && widgetOptions.categoryList.length && widgetOptions.tagList && widgetOptions.tagList.length) {
            query = { $and: [{ category: { $in: getCategoryList(widgetOptions) } }, { "tags.text": { $in: getTagList(widgetOptions) } }] };
        } else if (widgetOptions.categoryList && widgetOptions.categoryList.length) {
            query = { category: { $in: getCategoryList(widgetOptions) } };
        } else if (widgetOptions.tagList && widgetOptions.tagList.length) {
            query = { "tags.text": { $in: getTagList(widgetOptions) } };
        }
    } else if (widgetOptions.containrule === "Contains" && widgetOptions.anyrule === "All") {
        if (widgetOptions.categoryList && widgetOptions.categoryList.length && widgetOptions.tagList && widgetOptions.tagList.length) {
            query = { $and: [{ category: { $all: getCategoryList(widgetOptions) } }, { "tags.text": { $all: getTagList(widgetOptions) } }] };
        } else if (widgetOptions.categoryList && widgetOptions.categoryList.length) {
            query = { category: { $all: getCategoryList(widgetOptions) } };
        } else if (widgetOptions.tagList && widgetOptions.tagList.length) {
            query = { "tags.text": { $all: getTagList(widgetOptions) } };
        }
    } else if (widgetOptions.containrule === "Does not Contain" && widgetOptions.anyrule === "Any") {
        if (widgetOptions.categoryList && widgetOptions.categoryList.length && widgetOptions.tagList && widgetOptions.tagList.length) {
            query = { $and: [{ category: { $nin: getCategoryList(widgetOptions) } }, { "tags.text": { $nin: getTagList(widgetOptions) } }] };
        } else if (widgetOptions.categoryList && widgetOptions.categoryList.length) {
            query = { category: { $nin: getCategoryList(widgetOptions) } };
        } else if (widgetOptions.tagList && widgetOptions.tagList.length) {
            query = { "tags.text": { $nin: getTagList(widgetOptions) } };
        }
    } else if (widgetOptions.containrule === "Does not Contain" && widgetOptions.anyrule === "All") {
        if (widgetOptions.categoryList && widgetOptions.categoryList.length && widgetOptions.tagList && widgetOptions.tagList.length) {
            query = { $and: [{ category: { $not: { $all: getCategoryList(widgetOptions) } } }, { "tags.text": { $not: { $all: getTagList(widgetOptions) } } }] };
        } else if (widgetOptions.categoryList && widgetOptions.categoryList.length) {
            query = { category: { $not: { $all: getCategoryList(widgetOptions) } } };
        } else if (widgetOptions.tagList && widgetOptions.tagList.length) {
            query = { "tags.text": { $not: { $all: getTagList(widgetOptions) } } };
        }
    }
    const logicalOperator = widgetOptions?.anyrule === "Any" ? "or" : "and";
    const hasQueryForDynamicTags = !!Object.keys(queryForDynamicTags || {})?.length;
    const hasQuery = !!Object.keys(query || {})?.length;
    const combinedQuery = hasQueryForDynamicTags && hasQuery ? { [`$${logicalOperator}`]: [query, queryForDynamicTags] } : hasQueryForDynamicTags ? queryForDynamicTags : hasQuery ? query : {};
    return combinedQuery;
}

function SortByEventDate(cards: Array<WebContent & WithId>, specialCards: any[], ordertype: number, index: number): any[] {
    const assets = [];
    const cardsWithStartDate = cards.length > 0 ? cards.filter((element) => element.localizedCardContent[index].startDate !== null) : cards;
    const specialCardsWithStartDate = specialCards.length > 0 ? specialCards.filter((element) => element.localizedSpecialCard[index].startDate !== null) : specialCards;
    // If either array becomes empty after filtering out Nulls, push the contents of the other array
    if (cardsWithStartDate.length === 0) {
        assets.push(...specialCardsWithStartDate);
        return assets;
    }
    if (specialCardsWithStartDate.length === 0) {
        assets.push(...cardsWithStartDate);
        return assets;
    }
    const arrayToCompareWith = cardsWithStartDate.length >= specialCardsWithStartDate.length ? cardsWithStartDate : specialCardsWithStartDate;
    const arrayToBeCompared = cardsWithStartDate.length < specialCardsWithStartDate.length ? cardsWithStartDate : specialCardsWithStartDate;
    let comparator1 = "";
    let comparator2 = "";
    comparator1 = arrayToCompareWith === cardsWithStartDate ? "localizedCardContent" : "localizedSpecialCard";
    comparator2 = arrayToBeCompared === cardsWithStartDate ? "localizedCardContent" : "localizedSpecialCard";
    let i = 0;
    let j = 0;
    if (ordertype === 1) {
        // Ascending
        for (i = 0; i < arrayToCompareWith.length; i++) {
            if (j === arrayToBeCompared.length) {
                assets.push(arrayToCompareWith[i]); // Push remaining array assets, until the outer loop executes
                continue;
            }
            if (moment(arrayToCompareWith[i][comparator1][index].startDate).isBefore(moment(arrayToBeCompared[j][comparator2][index].startDate))) {
                assets.push(arrayToCompareWith[i]);
            } else {
                assets.push(arrayToBeCompared[j]);
                j++;
                i--;
            }
        }
        if (j !== arrayToBeCompared.length) {
            assets.push(...arrayToBeCompared.slice(j));
        }
    } else {
        // Descending
        for (i = 0; i < arrayToCompareWith.length; i++) {
            if (j === arrayToBeCompared.length) {
                assets.push(arrayToCompareWith[i]);
                continue;
            }
            if (moment(arrayToCompareWith[i][comparator1][index].startDate).isAfter(moment(arrayToBeCompared[j][comparator2][index].startDate))) {
                assets.push(arrayToCompareWith[i]);
            } else {
                assets.push(arrayToBeCompared[j]);
                j++;
                i--;
            }
        }
        // If still some elements from the shorter array are remaining, we are pushing the rest of them.
        if (j !== arrayToBeCompared.length) {
            assets.push(...arrayToBeCompared.slice(j));
        }
    }
    return assets;
}

async function sortWebContents(tagQuery: Query, orderType: 1 | -1, context: CMSProvidedProperties) {
    let sortedAssets: any[] = [];
    let order: Order;
    let query: Query = { ...tagQuery, "cardEnabled": true, "localizedCardContent.startDate": { $exists: true } };
    query = { ...query, $or: [{ cardSpecial: { $exists: false } }, { cardSpecial: false }] };
    order = { "localizedCardContent.startDate": orderType };
    const cardsAssets = await filterAssetsBasedOnPublishExpiration(await context.cmsApi.webContentApi.find({ query, order }), context);
    query = {}; // Resetting the above query conditions
    query = { ...tagQuery, "cardEnabled": true, "cardSpecial": true, "localizedSpecialCard.startDate": { $exists: true } };
    order = { "localizedSpecialCard.startDate": orderType };
    const specialCards = await filterAssetsBasedOnPublishExpiration(await context.cmsApi.webContentApi.find({ query, order }), context);
    if (cardsAssets.length > 0 || specialCards.length > 0) {
        sortedAssets = SortByEventDate(cardsAssets, specialCards, orderType, 0);
    }
    return sortedAssets;
}

export const getLocalizedLabel = (localizedContent: LocalizedAssetPublisherOptions[], context: CMSProviderProperties) => {
    const { site, currentLocale } = context;
    const localizedOptions: LocalizedAssetPublisherOptions = getLocalizedContent({ site, currentLocale, localizedContent }) || ({ label: "" } as LocalizedAssetPublisherOptions);
    const { label } = localizedOptions;
    return label;
};

export const getUniqueResortIdsForOwner = async (context: CMSProvidedProperties) => {
    const env = await getMxtsEnv(context);
    const customerIds = await getCustomerIdsFromLoginToken();
    const ownersUnits = (await Promise.all(customerIds?.map((customerId) => context?.mxtsApi?.units(env, { owner: customerId }))))?.flatMap((units) => units?.content);
    return [...new Set(ownersUnits?.map((unit) => unit?.resortId))];
};
